import React from 'react'

import ListGroup from 'react-bootstrap/ListGroup'

import Layout from '../components/layout'

export default class CustomerService extends React.Component {
  render() {
    const metaDescription = `Questions about your order? Shipping, tax, returns... don't hesitate to get in touch!`
    return (
      <Layout pageTitle="Customer Service" description={metaDescription}>
        <h1>Customer Service</h1>
        <ListGroup>
          <ListGroup.Item>
            <h2>Shipping</h2>
            <p>
              Please allow 24-48 hours for order processing after you have
              placed your order.
            </p>
            <p>
              We will ship all our orders by either UPS or USPS and should be on
              your doorstep within 5-10 business days anywhere in the U.S.
              (including Alaska and Hawaii) after it is processed.
            </p>
            <p>
              For expedited orders, please contact us at
              <a href="mailto:orders@4groupmath.com">
                &nbsp;orders@4groupmath.com&nbsp;
              </a>
              .
            </p>
          </ListGroup.Item>
          <ListGroup.Item>
            <h2>Sales Tax</h2>
            <p>
              Residents of Washington state will be charged the appropriate
              county/city sales tax of the Ship To address and calculated on the
              total of the order including shipping charges.
            </p>
          </ListGroup.Item>
          <ListGroup.Item>
            <h2>Returns & Exchanges</h2>
            <p>
              At Kuske Math, Inc. we pride ourselves in our products and our
              program. If you are not 100% satisfied with your purchase, a full
              refund will be provided for any goods returned to our warehouse
              unopened and in their original packaging within 30 days of
              purchase. Please include a note as to why materials are being
              returned, as well as the original sales receipt. Please state
              whether the product is to be exchanged or if you would prefer a
              refund. Shipping both ways is not refundable.
            </p>
            <p>
              If you received faulty or damaged materials we will replace them
              or refund the purchase price if you contact us within 30 days. In
              cases such as these, Kuske Math, Inc. will reimburse for shipping.
            </p>
            <h3>Please send returns to:</h3>
            <p>
              Kuske Math <br />
              10901 SE 26th St <br />
              Bellevue WA, 98004 <br />
              425-466-8564
            </p>
            <p>
              For customer service questions please contact us at{' '}
              <a href="mailto:orders@4groupmath.com">orders@4groupmath.com</a>
            </p>
          </ListGroup.Item>
        </ListGroup>
      </Layout>
    )
  }
}
